import { GridProps } from "@/types/components/grid/grid";


const Container = ({ full = false, size = "default", className = '', children }: GridProps) => {

    if (size == "small") {
        return (
            <div className={`container-small px-3 ${full ? 'w-full' : 'xl:w-984 lg:w-1024 md:w-768 w-full mx-auto' } ${className}`}>
                {children}
            </div>
        )
    } else {
        return (
            <div className={`container-default px-3 ${full ? 'w-full' : 'xl:w-1272 lg:w-1024 md:w-768 w-full mx-auto' } ${className}`}>
                {children}
            </div>
        )
    }
}

export default Container;